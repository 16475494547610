import React, { useEffect, useState } from "react"
import * as paramTypes from "use-query-params"
import { supportedParams } from "../components/blockComponents/Base"

const Params = () => {
  const [params, setParams] = useState({})
  useEffect(() => {
    const stringifiedParams = {}
    Object.entries(supportedParams).forEach(([key, value]) => {
      const typeName = Object.entries(paramTypes).find(
        type => type[1] === value
      )
      stringifiedParams[key] = typeName?.[0]
    })
    setParams(stringifiedParams)
  }, [])

  return <pre>{JSON.stringify(params, null, 2)}</pre>
}

export default Params
